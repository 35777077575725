import React, { useEffect, useState, useCallback, useContext } from "react";
import { MoreHorizontal } from 'lucide-react';
import { MasonryPhotoAlbum } from "react-photo-album";
import { UnstableInfiniteScroll as InfiniteScroll } from "react-photo-album/scroll";
import "react-photo-album/masonry.css";

import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/counter.css";
import useFetchConfig from "../../../Hooks/useFetchConfig";
import useFetch from "use-http";

import { MainContext } from "../../../Providers/MainContext";

export const Gallery = (props) => {
  const { canImpersonate } = useContext(MainContext);
  const { urls } = props;
  const [currentIndex, setCurrentIndex] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [activeUrls, setActiveUrls] = useState([]);
  const fetchConfig = useFetchConfig();
  const { patch, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );

  useEffect(() => {
    setActiveUrls(urls.filter(url => !url.disabled));
  }, [urls]);

  const handleDelete = useCallback(async () => {
    if (selectedPhotoIndex !== null) {
      setActiveUrls(prevUrls => prevUrls.filter((_, index) => index !== selectedPhotoIndex));
      setShowDeleteDialog(false);
      setSelectedPhotoIndex(null);
      let data = await patch(`/api/answer/${urls[selectedPhotoIndex].id}`, { disabled: true });
    }
  }, [selectedPhotoIndex]);

  const fetchPhoto = useCallback((num) => {
    return new Promise((resolve) => {
      if (num >= activeUrls.length) {
        resolve(null);
        return;
      }
      const image = new Image();
      image.onload = () => {
        resolve({
          index: num,
          src: activeUrls[num].url,
          width: image.naturalWidth,
          height: image.naturalHeight,
          description: activeUrls[num].description,
        });
      };
      image.onerror = () => {
        resolve({
          index: num,
          src: "https://placehold.co/200x200?text=Error",
          width: 200,
          height: 200,
          description: 'Error',
        });
      }
      image.src = activeUrls[num].url;
    });
  }, [activeUrls]);

  // Convert activeUrls to Lightbox slides format
  const slides = activeUrls.map(photo => ({
    src: photo.url,
    description: photo.description,
  }));

  return (
    <>
      <InfiniteScroll key={activeUrls.length} singleton fetch={fetchPhoto}>
        <MasonryPhotoAlbum
          photos={[]}
          spacing={20}
          componentsProps={{ container: { style: { marginBottom: 20 } } }}
          onClick={({ index }) => {
            setCurrentIndex(index);
          }}
          render={{
            extras: (_, { photo, index }) => {
              // only super user or multicustomer admin can delete photos
              if (canImpersonate)
                return (
                  <div className="position-absolute top-0 end-0">
                    <div className="dropdown">
                      <button
                        className="btn btn-dark btn-sm opacity-75 p-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={(e) => {
                          e.stopPropagation()
                          setShowDeleteDialog(true);
                          setSelectedPhotoIndex(index);
                        }}
                      >
                        <MoreHorizontal className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                )
            },
          }}
        />
      </InfiniteScroll>

      {/* Bootstrap Modal for Delete Confirmation */}
      <div className={`modal fade ${showDeleteDialog ? 'show' : ''}`}
        style={{ display: showDeleteDialog ? 'block' : 'none' }}
        tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Photo</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowDeleteDialog(false)}
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this photo?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowDeleteDialog(false)}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDelete}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showDeleteDialog && (
        <div className="modal-backdrop fade show"></div>
      )}

      <Lightbox
        slides={slides}
        open={currentIndex !== null}
        index={currentIndex}
        close={() => setCurrentIndex(null)}
        styles={{
          container: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            width: "90%",
            height: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "auto"
          }
        }}
        plugins={[Counter, Captions]}
        captions={{ descriptionTextAlign: "center" }}
      />
    </>
  );
};

export default Gallery;