import { Button, Table, Tooltip, OverlayTrigger } from "react-bootstrap";

const MiniButton = ({
  row,
  column,
  updateConfig,
  cellToColor,
  setGroupBy,
  selectedFilterGroups,
  setSelectedFilterGroups,
  isLoading,
  reset=false,
}) => {
  const renderTooltip = (column) => (
    <Tooltip className="primary-tooltip">{column}</Tooltip>
  );
  const handleButton = () => {
    setGroupBy(column);
    const selected = { ...selectedFilterGroups };
    if (reset) selected = {};
    updateConfig.forEach((item) => {
      if (row[item.source]) {
        selected[item.key] = [row[item.source]];
      }
    });
    setSelectedFilterGroups(selected);
  };
  const variantColor = cellToColor(row, column);
  return (
    <td>
      <OverlayTrigger overlay={renderTooltip(column)}>
        <Button
          variant={variantColor}
          className="w-100 text-white"
          onClick={handleButton}
          disabled={isLoading}
        >
          {row[column]}
        </Button>
      </OverlayTrigger>
    </td>
  );
};

const TitleButton = ({
  title,
  deleteKeys,
  setGroupBy,
  setSelectedFilterGroups,
  isLoading,
}) => {
  const renderTooltip = () => (
    <Tooltip className="primary-tooltip">
      This will reload the full primary table.
    </Tooltip>
  );
  const handleButton = () => {
    setGroupBy("");
    setSelectedFilterGroups((prevGroups) => {
      const updatedGroups = { ...prevGroups };
      for (const key of deleteKeys) {
        delete updatedGroups[key];
      }
      return updatedGroups;
    });
  };
  return (
    <OverlayTrigger overlay={renderTooltip()}>
      <Button
        className="text-white"
        onClick={handleButton}
        disabled={isLoading}
      >
        {title}
      </Button>
    </OverlayTrigger>
  );
};

const HeadlineButton = ({ content, tooltip, isLoading }) => {
  const renderTooltip = () => (
    <Tooltip className="primary-tooltip">{tooltip}</Tooltip>
  );
  return (
    <OverlayTrigger overlay={renderTooltip()}>
      <Button
        variant="dark-grey"
        className="text-white mx-1"
        disabled={isLoading}
        style={{ cursor: "default" }}
      >
        {content}
      </Button>
    </OverlayTrigger>
  );
};
const HeadlineButtons = ({ buttonConfig, isLoading }) => {
  return (
    <>
      {buttonConfig.map((config, index) => (
        <HeadlineButton
          key={index}
          content={config.content}
          tooltip={config.tooltip}
          isLoading={isLoading}
        />
      ))}
    </>
  );
};
const ExportButton = ({ isLoading, handleExport }) => {
  const renderTooltip = () => (
    <Tooltip className="primary-tooltip">
      This will eventually export an expanded table.
    </Tooltip>
  );
  return (
    <OverlayTrigger overlay={renderTooltip()}>
      <Button
        className="text-white mx-1"
        onClick={handleExport}
        disabled={isLoading}
      >
        Export
      </Button>
    </OverlayTrigger>
  );
};

const FiltersButton = ({ isLoading, handleToggleFilterModal }) => {
  const renderTooltip = () => (
    <Tooltip className="primary-tooltip">
      This will open the filter-modal.
    </Tooltip>
  );
  return (
    <OverlayTrigger overlay={renderTooltip()}>
      <Button
        className="text-white mx-1"
        onClick={handleToggleFilterModal}
        disabled={isLoading}
      >
        Filters
      </Button>
    </OverlayTrigger>
  );
};

const RefreshButton = ({ isLoading, setSelectedFilterGroups }) => {
  const renderTooltip = () => (
    <Tooltip className="primary-tooltip">
      This will refresh your tables.
    </Tooltip>
  );
  const handleRefresh = () => {
    setSelectedFilterGroups((prev) => {
      return { ...prev };
    });
  };
  return (
    <OverlayTrigger overlay={renderTooltip()}>
      <Button
        className="text-white mx-1"
        onClick={handleRefresh}
        disabled={isLoading}
      >
        Refresh
      </Button>
    </OverlayTrigger>
  );
};

const Cell = ({ clss, value, tooltip }) => {
  const renderTooltip = (column) => (
    <Tooltip className="primary-tooltip">{tooltip}</Tooltip>
  );
  return (
    <td className={clss}>
      <OverlayTrigger overlay={renderTooltip(tooltip)}>
        <div>{value}</div>
      </OverlayTrigger>
    </td>
  );
};

const OpsTable = ({ columns, rows, assignCell, args }) => {
  return (
    <Table
      hover
      className="rounded-3 overflow-hidden"
      size="sm"
      responsive="md"
    >
      <thead>
        <tr className="border-0">
          {columns && columns.map((column) => <th key={column}>{column}</th>)}
        </tr>
      </thead>
      <tbody>
        {rows &&
          rows.map((row, rowIndex) => (
            <tr className="border-0" key={rowIndex}>
              {columns &&
                columns.map((column) =>
                  assignCell(row, column, rowIndex, args),
                )}
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export {
  OpsTable,
  Cell,
  MiniButton,
  TitleButton,
  HeadlineButton,
  HeadlineButtons,
  RefreshButton,
  FiltersButton,
  ExportButton,
};
