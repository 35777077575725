import React, { useState, useEffect, useContext } from 'react';
import { Button, Container, Table, Row, Col } from 'react-bootstrap';
import { MainContext } from "../../Providers/MainContext";
import useFetchConfig from "../../Hooks/useFetchConfig";
import useFetch from "use-http";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function CustomerListPage({ edit = true }) {
    const { setImpersonatedCustomerId } = useContext(MainContext);

    const navigate = useNavigate();
    const [customers, setCustomers] = useState([]);
    const fetchConfig = useFetchConfig();
    const {
        get,
        response,
    } = useFetch(process.env.REACT_APP_API_URL, fetchConfig);
    const { t } = useTranslation();

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = async () => {
        try {
            const customers = await get('/api/customers');
            setCustomers(customers);
        } catch (error) {
            console.error(error);
        }
    };

    const containerStyle = {
        maxWidth: '800px', // Adjust the max-width as needed
        margin: '0 auto', // This centers the container
    };

    const linkCol = ({ edit }) => {
        if (edit) {
            return "Edit"
        } else {
            return "Select"
        }
    }
    const makeButton = ({ edit, customer }) => {
        if (edit) {
            return <Button variant="primary" onClick={() => navigate(`/customers/${customer.id}/edit`)}>Edit</Button>;
        }
        return <Button
            variant="primary"
            onClick={() => {
                setImpersonatedCustomerId(customer.id);
                navigate(`/dashboard-create`);
            }}
        >
            Select
        </Button>
    }

    return (
        <Container style={containerStyle}>
            {/*  */}

            <Row className="mt-4 mb-4">
                <Col xs={8} className="d-flex">
                    <h2 className="text-white">Customer List</h2>
                </Col>
                <Col xs={4} className="d-flex justify-content-end">
                    <Button variant="primary " className="mx-2" onClick={() => navigate("/customers/new")}>New Customer</Button>
                    {edit && <Button variant="primary mx-2" onClick={() => navigate("/home")}>Home</Button>}
                </Col>
            </Row>

            <Table hover className="rounded-3 overflow-hidden" size="sm" responsive="md">
                <thead>
                    <tr className="border-0">
                        <th>Customer ID</th>
                        <th>Customer</th>
                        <th>Description</th>
                        <th>{linkCol({ edit })}</th>
                    </tr>
                </thead>
                <tbody>
                    {customers && customers.map((customer, index) => (
                        <tr className="border-0" key={customer.id}>
                            <td>{customer.id}</td>
                            <td>{customer.title}</td>
                            <td>{customer.description}</td>
                            <td>{makeButton({ edit, customer })}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

export function CustomerSelectionPage() {
    return CustomerListPage({ edit: false });
}