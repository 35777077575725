import { Col, Spinner } from "react-bootstrap";

const LoadingSpinner = () => {
  return (
    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%' }}>
      <Spinner animation="border" role="status" variant="secondary" size="xl">
      </Spinner>
      <h2 className="mx-3">Loading...</h2>
    </Col>
  );
}
export default LoadingSpinner;