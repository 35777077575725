import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row } from "react-bootstrap";
import useFetch from "use-http";

import useFetchConfig from "../../Hooks/useFetchConfig";
import CustomerUserListTable from "../User/CustomerUserListTable";
import SurveysTable from "./SurveysTable";

export default function DashboardEditPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const { id } = useParams();
  const fetchConfig = useFetchConfig();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [dashboard, setDashboard] = useState(null);
  const [viewers, setViewers] = useState([]);
  const [surveys, setSurveys] = useState([]);

  const {
    get,
    post,
    patch,
    delete: deleter,
    response,
  } = useFetch(process.env.REACT_APP_API_URL, fetchConfig);

  useEffect(() => {
    fetchDashboard();
    fetchDashSurveys();
  }, []);

  const fetchDashboard = async () => {
    let data = await get(`/api/dashboard/${id}`);
    if (response.ok) {
      setError(null);
      setDashboard(data);
      setTitle(data.title);
      setDescription(data.description);
      setViewers(data.viewers);
    } else {
      setError("Dashboard not found");
    }
  };

  const fetchDashSurveys = async () => {
    let data = await get(`/api/dashboard/${id}/surveys`);
    if (response.ok) {
      setError(null);
      setSurveys(data);
    } else {
      setError("Dash-surveys not found.");
    }
  };

  const _handleAddUser = async (userId) => {
    let data = await post(`/api/dashboard/${id}/viewer/${userId}`);
    if (response.ok) {
      setError(null);
      setViewers(data.viewers);
    }
  };

  const _handleRemoveUser = async (userId) => {
    let data = await deleter(`/api/dashboard/${id}/viewer/${userId}`);
    if (response.ok) {
      setError(null);
      setViewers(data.viewers);
    }
  };

  const _handleDisableChange = async (e) => {
    const newDisabledState = e.target.checked;

    // Update the dashboard's disabled state locally
    setDashboard((prevDashboard) => ({
      ...prevDashboard,
      disabled: newDisabledState,
    }));

    // Optionally, update the backend when toggled
    await patch(`/api/dashboard/${id}`, {
      disabled: newDisabledState,
    });
    if (response.ok) {
      setError(null);
    } else {
      // Handle error if the update failed
      setError("Failed to update dashboard");

      // Revert the disabled state in case of failure
      setDashboard((prevDashboard) => ({
        ...prevDashboard,
        disabled: !newDisabledState,
      }));
    }
  };

  return (
    <Col xs={8} md={6} lg={4} xl={4} className="mx-auto">
      <br />
      <h1 className="text-light">{"Edit Dashboard"}</h1>
      <br />
      <Row>{error && <p className="text-danger">{error}</p>}</Row>
      {dashboard && (
        <>
        <Row>
          <Form.Group className="mb-3" controlId="dashboardEdit.title">
            <Form.Label className="text-light">{t("Title")}</Form.Label>
            <Form.Control
              className=""
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3" controlId="dashboardEdit.description">
            <Form.Label className="text-light">{t("Description")}</Form.Label>
            <Form.Control
              className=""
              as="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Col className="mx-auto">
            <Form>
              <Form.Group
                className="mb-3 d-flex align-items-center"
                controlId="dashboardDisabled.title"
              >
                <Form.Label className="text-light my-2">
                  {t("Disabled Status")}
                </Form.Label>
                <Form.Switch
                  id={`switch-disabled`}
                  checked={dashboard.disabled} // Use the switchStates prop to determine checked state
                  onChange={_handleDisableChange}
                  className="grey-green-switch btn-primary m-2"
                />
                <Form.Label className="text-light my-2">
                  {dashboard.disabled ? "Disabled" : "Enabled"}
                </Form.Label>
              </Form.Group>
            </Form>
          </Col>
          <Col className="mx-auto">
            <Button
              className="w-100"
              onClick={async () => {
                await patch(`/api/dashboard/${id}`, {
                  title: title,
                  description: description,
                });
                if (response.ok) {
                  setError(null);
                  navigate(`/dashboard/${id}`);
                }
              }}
            >
              {t("Save")}
            </Button>
          </Col>
        </Row>
        <Row>
          <CustomerUserListTable
            title={t("Viewers")}
            selectedUserIds={viewers}
            handleAddUser={(userId) => _handleAddUser(userId)}
            handleRemoveUser={(userId) => _handleRemoveUser(userId)}
          />
        </Row>
        <Row>
          <SurveysTable surveys={surveys} />
        </Row>
        </>
      )}
      <br />
      {dashboard && (
        <Button
          variant="primary"
          className="text-white w-100"
          onClick={() => {
            navigate(`/dashboard/${id}`);
          }}
          disabled={dashboard.disabled}
        >
          View
        </Button>
      )}
      <Row style={{ height: "50px" }}></Row>
    </Col>
  );
}
