import React, { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import Button from "react-bootstrap/Button";
/**
 * A React functional component that handles the download of a zip file containing photos.
 * The component accepts an array of photo URLs and an error handler function as props.
 * It displays a button that, when clicked, initiates the download process in percentage.
 * The download process is performed in chunks to avoid memory issues for clients' computers or browsers with large numbers of photos.
 *
 * @param {Object[]} urls - An array of objects containing photo URLs and descriptions.
 * @param {Function} setError - A function to handle any errors that occur during the download process.
 * @return {JSX.Element} A JSX element representing the download button.
 */
const ZipDownloader = ({ urls, setError }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [messageAfterDownload, setMessageAfterDownload] = useState('Zipping ...');

    const CHUNK_SIZE = 500; // Number of files per chunk usually 500 MB ~ 1GB
    const MAX_ATTEMPTS = 5; // Maximum number of retry attempts

    const downloadZip = async () => {
        if (!urls || urls.length === 0) {
            console.error('No Photos');
            setError('No Photos');
            return;
        }
        setIsLoading(true);
        setProgress(0);
        const startTime = new Date(); // Record the start time

        let totalFiles = urls.length;
        let totalDownloaded = 0;
        let failedDownloads = 0;
        let zipIndex = 1;

        // recursive call for exponential backoff
        const downloadFileWithRetry = async (url, filename, attempt = 1) => {
            try {
                const response = await axios.get(url, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Cache-Control': 'no-cache, no-store, must-revalidate',
                        'Pragma': 'no-cache',
                        'Expires': '0'
                    },
                    onDownloadProgress: (progressEvent) => {
                        const fileProgress = progressEvent.loaded / progressEvent.total;
                        const overallProgress = ((totalDownloaded + fileProgress) / totalFiles) * 100;
                        setProgress(Math.round(overallProgress));
                    }
                });

                return response.data;
            } catch (error) {
                if (attempt < MAX_ATTEMPTS) {
                    const delay = Math.pow(2, attempt) * 1000; // Exponential backoff: 2^attempt seconds
                    console.error(`Error downloading ${url}, attempt ${attempt}:`, error);
                    await new Promise(resolve => setTimeout(resolve, delay));
                    return downloadFileWithRetry(url, filename, attempt + 1);
                } else {
                    console.error(`Failed to download ${url} after ${MAX_ATTEMPTS} attempts.`);
                    failedDownloads += 1;
                    return null;
                }
            }
        };

        // in every 500 files, make a new Zip file
        const processChunk = async (chunkUrls, chunkIndex) => {
            let zip = new JSZip();
            let accumulatedSize = 0;

            // Download all files in the chunk in parallel with retry logic
            const downloadPromises = chunkUrls.map(async (photoObject, i) => {
                const filteredDescription = (photoObject['description'] || '').replace(/[/\\?%*:|"<>]/g, '_');
                const filename = `${filteredDescription}_image${chunkIndex * CHUNK_SIZE + i + 1}.jpg`;
                const url = photoObject['url'];

                const fileData = await downloadFileWithRetry(url, filename);
                if (fileData) {
                    zip.file(filename, fileData);
                    accumulatedSize += fileData.byteLength;
                    totalDownloaded += 1;
                }
            });

            // Wait for all downloads in the chunk to complete
            await Promise.all(downloadPromises);

            if (accumulatedSize > 0) {
                console.log(`Generating zip file part ${zipIndex}...`);
                const content = await zip.generateAsync({ type: 'blob' });
                saveAs(content, `photos_part${zipIndex}.zip`);
                zipIndex += 1;
            }
        };

        try {
            // Process in chunks
            for (let i = 0; i < urls.length; i += CHUNK_SIZE) {
                const chunkUrls = urls.slice(i, i + CHUNK_SIZE);
                await processChunk(chunkUrls, i / CHUNK_SIZE);
            }

        } catch (error) {
            console.error('Error in zip process:', error);
            setError('An error occurred while generating the zip file.');
        } finally {
            setIsLoading(false);
            setMessageAfterDownload('Download Completed');
            if (failedDownloads > 0) {
                setError(`${failedDownloads} photos failed to download. Please try again.`);
            } else {
                setError('');
            }
        }
    };

    const getButtonText = () => {
        if (progress === 100) {
            return messageAfterDownload;
        } else if (isLoading) {
            return `Downloading ${progress}%`;
        } else {
            return 'Download Photos';
        }
    };

    return (
        <>
            <Button
                onClick={downloadZip} disabled={isLoading}
                className='text-white'
                style={{ height: '70px' }}>
                {getButtonText()}
            </Button>
        </>
    );
};

export default ZipDownloader;