import React from "react";
import { Card, CardHeader, Col, Row } from "react-bootstrap";
import {
  OpsTable,
  MiniButton,
  TitleButton,
  HeadlineButtons,
  ExportButton,
  FiltersButton,
  RefreshButton,
  Cell,
} from "./utils";
import {
  dbToLocale,
  formatStdDateLocaleTime,
  getColorFromPercentage,
  partialObject,
} from "../../../Utils/utilFunctions";
import useFetchConfig from "../../../Hooks/useFetchConfig";
import { useFetch } from "use-http";

function FulfillmentCard({
  data,
  filterParams,
  handleExport,
  isLoading,
  highlightName,
}) {
  const { groupBy, setGroupBy, selectedFilterGroups, setSelectedFilterGroups, handleToggleFilterModal } = filterParams;
  const fetchConfig = useFetchConfig();
  const { get, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const rows = data.value.rows;
  if (rows === undefined) return <></>;
  const columns = data.value.columns;
  const headline = data.value.headline;
  const primaryButtonColumns = [
    "Open Shifts",
    "Lost Days",
    "Pending Shifts",
    "Booked Shifts",
    "Declined",
  ];
  const secondary = data.value.secondary;
  const buttonConfig = [
    {
      content: `Booked: ${headline.booked_shifts} / ${headline.total_shifts} = ${Math.round((100 * headline.booked_shifts) / headline.total_shifts)}%`,
      tooltip: "Booked shifts data for all selected jobs.",
    },
    {
      content: `Pending: ${headline.pending_shifts} / ${headline.total_shifts} = ${Math.round((100 * headline.pending_shifts) / headline.total_shifts)}%`,
      tooltip: "Pending shifts data for all selected jobs.",
    },
    {
      content: `Open: ${headline.open_shifts} / ${headline.total_shifts} = ${Math.round((100 * headline.open_shifts) / headline.total_shifts)}%`,
      tooltip: "Open shifts data for all selected jobs.",
    },
  ];

  const handleFileNameAPI = async (fileName) => {
    try {
      const data = await get(`api/timesheet?filename=${fileName}&db=DE`);
      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
      }
    } catch (error) {
      console.error("Error calling API:", error);
    }
  };
  const cellToColor = (row, column) => {
    let variantColor = "primary";
    let denom = "Total Shifts";
    switch (column) {
      case "Open Shifts":
        variantColor = getColorFromPercentage(row[column], row[denom], 10, 70);
        break;
      case "Lost Days":
        variantColor = getColorFromPercentage(row[column], row[denom], 5, 30);
        break;
      case "Pending Shifts":
        variantColor = getColorFromPercentage(row[column], row[denom], 20, 50);
        break;
      case "Booked Shifts":
        variantColor = getColorFromPercentage(
          row[column],
          row[denom],
          60,
          90,
          false,
        );
        break;
      case "Declined":
        variantColor = getColorFromPercentage(row[column], row[denom], 10, 20);
        break;
    }
    return variantColor;
  };
  const assignFulfillmentCell = (row, column, rowIndex, args) => {
    const key = `${rowIndex}-${column}`;
    const buttonColumns = args["buttonColumns"];
    const highlightName = args["highlightName"];
    if (buttonColumns.includes(column) && groupBy !== column) {
      return (
        <MiniButton
          key={key}
          row={row}
          column={column}
          updateConfig={[{ key: "job_id", source: "Job ID" }]}
          cellToColor={cellToColor}
          setGroupBy={setGroupBy}
          selectedFilterGroups={selectedFilterGroups}
          setSelectedFilterGroups={setSelectedFilterGroups}
          isLoading={isLoading}
        />
      );
    } else if (highlightName && column === "Job Name") {
      return (
        <Cell
          key={key}
          value={
            <a
              href={`https://portal-de.weareelevate.global/jobs/${row["Job ID"]}`}
              target="_blank"
            >
              {row["Job Name"]}
            </a>
          }
          tooltip={column}
        />
      );
    } else if (
      row[column] !== null &&
      (typeof row[column] === "number" || row[column].endsWith("%"))
    ) {
      return (
        <Cell key={key} clss="text-end" value={row[column]} tooltip={column} />
      );
    } else if (column === "Shift Date") {
      return (
        <Cell
          key={key}
          clss=""
          value={formatStdDateLocaleTime(row[column], dbToLocale("DE"))}
          tooltip={column}
        />
      );
    } else if (column === "Timesheet" && secondary) {
      return (
        <Cell
          key={key}
          clss=""
          value={
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleFileNameAPI(row[column]);
              }}
            >
              {row[column]}
            </a>
          }
          tooltip={column}
        />
      );
    } else {
      return <Cell key={key} clss="" value={row[column]} tooltip={column} />;
    }
  };

  const header = () => {
    return (
      <Row className="d-flex justify-content-between align-items-center">
        <Col>
          {groupBy ? (
            <TitleButton
              title={data.name}
              deleteKeys={["job_id"]}
              setGroupBy={setGroupBy}
              setSelectedFilterGroups={setSelectedFilterGroups}
              isLoading={isLoading}
            />
          ) : (
            <h5>{data.name}</h5>
          )}
        </Col>
        <Col className="mx-auto"></Col>
        <Col xs="auto">
          <HeadlineButtons isLoading={isLoading} buttonConfig={buttonConfig} />
          <ExportButton isLoading={isLoading} handleExport={handleExport} />
          <FiltersButton
            isLoading={isLoading}
            handleToggleFilterModal={handleToggleFilterModal}
          />
          <RefreshButton
            isLoading={isLoading}
            setSelectedFilterGroups={setSelectedFilterGroups}
          />
        </Col>
      </Row>
    );
  };
  return (
    <Card>
      <CardHeader>{header()}</CardHeader>
      <Card.Body>
        <OpsTable
          columns={columns}
          rows={rows}
          assignCell={assignFulfillmentCell}
          args={{
            buttonColumns: primaryButtonColumns,
            highlightName: highlightName,
          }}
        />
        {secondary && (
          <OpsTable
            columns={secondary.columns}
            rows={secondary.rows}
            assignCell={assignFulfillmentCell}
            args={{
              buttonColumns: primaryButtonColumns,
              highlightName: highlightName,
            }}
          />
        )}
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
}

const OpsFulfillmentCard = partialObject(FulfillmentCard, {
  highlightName: true,
});
const ClientFulfillmentCard = partialObject(FulfillmentCard, {
  highlightName: false,
});

export { ClientFulfillmentCard, OpsFulfillmentCard };
