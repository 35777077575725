import { useState, useContext, useEffect } from "react";

import useFetch from "use-http";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import useFetchConfig from "../../Hooks/useFetchConfig";

import { MainContext } from "../../Providers/MainContext";

export default function MerchDeleteodal(props) {
  const fetchConfig = useFetchConfig();
  const { delete: deleter, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const { customer } = useContext(MainContext);
  const [brand, setBrand] = useState(null);
  const [category, setCategory] = useState(null);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setBrand(props.merch.brand);
    setCategory(props.merch.category);
    setProduct(props.merch.product);
  }, [props.merch]);

  const handleClose = () => props.handleClose();

  const handleDelete = async () => {
    await deleter(`/api/customer/${customer.id}/merch/${props.merch.id}`);
    if (response.ok) {
      setError(null);
      handleClose();
    } else {
      setError("Error deleting merch");
    }
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton className="bg-light">
        <Modal.Title>
          Delete Brand & Product {props.merch.id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        {error && <p className="text-danger">{error}</p>}
        <Form>
          <fieldset disabled>
            <Form.Group className="mb-3" controlId="merchEditForm.brand">
              <Form.Label>Country</Form.Label>
              <Form.Control value={brand} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="merchEditForm.category">
              <Form.Label>Category</Form.Label>
              <Form.Control value={category} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="merchEditForm.product">
              <Form.Label>Product</Form.Label>
              <Form.Control value={product} />
            </Form.Group>
          </fieldset>
        </Form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
