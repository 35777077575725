import useFetch from "use-http";
import useFetchConfig from "../../Hooks/useFetchConfig";

import { CardHeader, Card, Col, Image } from "react-bootstrap";

export default function ImageInputCard({
  fileNameGenerator,
  image,
  setImage,
  imageError,
  setImageError,
  cardHeader="Image",
  disabled=false,
}) {
  // fileNameGenerator needs to take fileExtension as a parameter and return a string.
  const fetchConfig = useFetchConfig();
  const { get, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );

  const handleImage = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const fileType = file.type;
      const fileExtension = fileType.split("/")[1];
      const queryParams = new URLSearchParams({
        file_name: fileNameGenerator(fileExtension),
        file_type: fileType,
        directory: "images/metrictemplates/",
      }).toString();
      let data = await get(`/api/presigned-s3-post-url?${queryParams}`);
      if (response.ok) {
        const formData = new FormData();
        Object.entries(data.data.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", file);
        const uploadData = await fetch(data.data.url, {
          method: "POST",
          body: formData,
          mode: "cors",
        });

        if (uploadData.ok) {
          let imageData = await get(`/api/presigned-s3-get-url?${queryParams}`);
          if (response.ok) {
            setImage(imageData);
            setImageError("");
          } else {
            setImageError("Failed to load image.");
          }
        } else {
          setImageError("Failed to upload image.");
        }
      }
    } catch (error) {
      setImageError(error.message);
    }
  };

  return (
    <Col>
      <Card className="h-100">
        <CardHeader>{cardHeader}</CardHeader>
        <Card.Body className="flex-grow-1 d-flex flex-column">
          {image && image.url && <Image src={image.url} />}
          <input
            type="file"
            accept="image/*"
            onChange={handleImage}
            className="mt-auto"
            disabled={disabled}
          />
        </Card.Body>
        <Card.Footer className="text-center">
          {imageError && <p className="text-danger">{imageError}</p>}
        </Card.Footer>
      </Card>
    </Col>
  );
}
