import { useState, useEffect, useContext } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import useFetch from "use-http";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { Pencil, Trash } from "react-bootstrap-icons";

import useFetchConfig from "../../Hooks/useFetchConfig";

import { MainContext } from "../../Providers/MainContext";
import LocationEditModal from "./LocationEditModal";
import LocationDeleteModal from "./LocationDeleteModal";

export default function LocationPage() {
  const fetchConfig = useFetchConfig();
  const {
    get,
    post,
    response,
  } = useFetch(process.env.REACT_APP_API_URL, fetchConfig);
  const { customer } = useContext(MainContext);
  const [locations, setLocations] = useState(null);
  const [error, setError] = useState(null);
  const [countryFilter, setCountryFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [regionFilter, setRegionFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState(null);
  const [city, setCity] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    fetchLocations();
  }, [customer]);

  const fetchLocations = async () => {
    if (!customer.id) return;
    let data = await get(`/api/customer/${customer.id}/stores`);
    if (response.ok) {
      setError(null);
      setLocations(data);
    } else {
      setError("Error Loading Locations");
    }
  };

  const _locationFilter = (location) => {
    return (
      location.city.toLowerCase().includes(cityFilter.toLowerCase()) &&
      location.country.toLowerCase().includes(countryFilter.toLowerCase()) &&
      location.location.toLowerCase().includes(locationFilter.toLowerCase()) &&
      location.region.toLowerCase().includes(regionFilter.toLowerCase())
    );
  };

  const handleLocationCreateSubmit = async () => {
    if (!country) {
      setError("Please fill in a country.")
      return
    } else if (!region) {
      setError("Please fill in a region.")
      return
    } else if (!city) {
      setError("Please fill in a city.")
      return
    } else if (!location) {
      setError("Please fill in a location.")
      return
    }
    let data = await post(`/api/customer/${customer.id}/stores`, {
      country,
      region,
      city,
      location,
    });
    if (response.ok) {
      fetchLocations()
      setError(null);
    } else {
      setError("Error creating location.");
    }
  };

  return (
    <Col xs={8} md={6} lg={6} xl={6} className="mx-auto">
      {selectedLocation && (
        <LocationEditModal
          show={showEditModal}
          location={selectedLocation}
          handleClose={() => {
            setShowEditModal(false);
            fetchLocations();
          }}
        />
      )}
      {selectedLocation && (
        <LocationDeleteModal
          show={showDeleteModal}
          location={selectedLocation}
          handleClose={() => {
            setShowDeleteModal(false);
            fetchLocations();
          }}
        />
      )}
      <br />
      <h1 className="text-light">Locations</h1>
      <br />
      <Row>
        {error && <p className="text-danger">{error}</p>}
        <Table hover className="rounded-3 overflow-hidden">
          <thead>
            <tr className="border-0">
              <th></th>
              <th>
                <Form.Control
                  placeholder="Country"
                  type="country"
                  onChange={(e) => setCountry(e.target.value)}
                />
              </th>
              <th>
                <Form.Control
                  placeholder="Region"
                  type="region"
                  onChange={(e) => setRegion(e.target.value)}
                />
              </th>
              <th>
                <Form.Control
                  placeholder="City"
                  type="city"
                  onChange={(e) => setCity(e.target.value)}
                />
              </th>
              <th>
                <Form.Control
                  placeholder="Location"
                  type="location"
                  onChange={(e) => setLocation(e.target.value)}
                />
              </th>
              <th>
                <Button className="w-100" onClick={() => handleLocationCreateSubmit(true)}>Create</Button>
              </th>
            </tr>
            <tr className="border-0">
              <th>ID</th>
              <th>
                <Form.Control
                  placeholder="Country"
                  type="country"
                  onChange={(e) => setCountryFilter(e.target.value)}
                />
              </th>
              <th>
                <Form.Control
                  placeholder="Region"
                  type="region"
                  onChange={(e) => setRegionFilter(e.target.value)}
                />
              </th>
              <th>
                <Form.Control
                  placeholder="City"
                  type="city"
                  onChange={(e) => setCityFilter(e.target.value)}
                />
              </th>
              <th>
                <Form.Control
                  placeholder="Location"
                  type="location"
                  onChange={(e) => setLocationFilter(e.target.value)}
                />
              </th>
              <th><Button disabled className="bg-grey w-100">Search</Button></th>
            </tr>
          </thead>
          <tbody>
            {locations && locations.length === 0 && <p>No locations found</p>}
            {locations &&
              locations
                .filter((location) => _locationFilter(location))
                .map((location) => (
                  <tr className="border-0">
                    <td>{location.id}</td>
                    <td>{location.country}</td>
                    <td>{location.region}</td>
                    <td>{location.city}</td>
                    <td>{location.location}</td>
                    <td style={{ textAlign: 'center', }}>
                      <Pencil
                        onClick={() => {
                          setSelectedLocation(location);
                          setShowEditModal(true);
                        }}
                      />
                      &nbsp; &nbsp; &nbsp;
                      <Trash
                        onClick={() => {
                          setSelectedLocation(location);
                          setShowDeleteModal(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </Row>
    </Col>
  );
}
