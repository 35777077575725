import { useState, useEffect, useContext } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import useFetch from "use-http";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { Pencil, Trash } from "react-bootstrap-icons";

import useFetchConfig from "../../Hooks/useFetchConfig";

import { MainContext } from "../../Providers/MainContext";
import MerchEditModal from "./MerchEditModal";
import MerchDeleteModal from "./MerchDeleteModal";

export default function MerchPage() {
  const fetchConfig = useFetchConfig();
  const {
    get,
    post,
    response,
  } = useFetch(process.env.REACT_APP_API_URL, fetchConfig);
  const { customer } = useContext(MainContext);
  const [merch, setMerch] = useState(null);
  const [error, setError] = useState(null);
  const [brandFilter, setBrandFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [productFilter, setProductFilter] = useState("");
  const [selectedMerch, setSelectedMerch] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [brand, setBrand] = useState(null);
  const [category, setCategory] = useState(null);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    fetchMerch();
  }, [customer]);

  const fetchMerch = async () => {
    if (!customer.id) return;
    let data = await get(`/api/customer/${customer.id}/merch`);
    if (response.ok) {
      setError(null);
      setMerch(data);
    } else {
      setError("Error loading merch");
    }
  };

  const _merchFilter = (merch) => {
    return (
      merch.brand.toLowerCase().includes(brandFilter.toLowerCase()) &&
      merch.category.toLowerCase().includes(categoryFilter.toLowerCase()) &&
      merch.product.toLowerCase().includes(productFilter.toLowerCase())
    );
  };

  const handleMerchCreateSubmit = async () => {
    if (!brand) {
      setError("Please fill in a brand.")
      return
    } else if (!category) {
      setError("Please fill in a category.")
      return
    } else if (!product) {
      setError("Please fill in a product.")
      return
    }
    let data = await post(`/api/customer/${customer.id}/merch`, {
      brand,
      category,
      product,
    });
    if (response.ok) {
      fetchMerch();
      setError(null);
    } else {
      setError("Error updating merch.");
    }
  };

  return (
    <Col xs={8} md={6} lg={6} xl={6} className="mx-auto">
      {selectedMerch && (
        <MerchEditModal
          show={showEditModal}
          merch={selectedMerch}
          handleClose={() => {
            setShowEditModal(false);
            fetchMerch();
          }}
        />
      )}
      {selectedMerch && (
        <MerchDeleteModal
          show={showDeleteModal}
          merch={selectedMerch}
          handleClose={() => {
            setShowDeleteModal(false);
            fetchMerch();
          }}
        />
      )}
      <br />
      <h1 className="text-light">Brands & Products</h1>
      <br />
      <Row>
        {error && <p className="text-danger">{error}</p>}

        <Table hover className="rounded-3 overflow-hidden">
          <thead>
            <tr className="border-0">
              <th></th>
              <th>
                <Form.Control
                  placeholder="Brand"
                  type="brand"
                  onChange={(e) => setBrand(e.target.value)}
                />
              </th>
              <th>
                <Form.Control
                  placeholder="Category"
                  type="category"
                  onChange={(e) => setCategory(e.target.value)}
                />
              </th>
              <th>
                <Form.Control
                  placeholder="Product"
                  type="product"
                  onChange={(e) => setProduct(e.target.value)}
                />
              </th>
              <th>
                <Button className="w-100" onClick={() => handleMerchCreateSubmit(true)}>Create</Button>
              </th>
            </tr>
            <tr className="border-0">
              <th>ID</th>
              <th>
                <Form.Control
                  placeholder="Brand"
                  type="brand"
                  onChange={(e) => setBrandFilter(e.target.value)}
                />
              </th>
              <th>
                <Form.Control
                  placeholder="Category"
                  type="category"
                  onChange={(e) => setCategoryFilter(e.target.value)}
                />
              </th>
              <th>
                <Form.Control
                  placeholder="Product"
                  type="product"
                  onChange={(e) => setProductFilter(e.target.value)}
                />
              </th>
              <th><Button disabled className="bg-grey w-100">Search</Button></th>
            </tr>
          </thead>
          <tbody>
            {merch && merch.length === 0 && <p>No merch found</p>}
            {merch &&
              merch
                .filter((mrch) => _merchFilter(mrch))
                .map((mrch) => (
                  <tr className="border-0">
                    <td>{mrch.id}</td>
                    <td>{mrch.brand}</td>
                    <td>{mrch.category}</td>
                    <td>{mrch.product}</td>
                    <td style={{ textAlign: 'center', }}>
                      <Pencil
                        onClick={() => {
                          setSelectedMerch(mrch);
                          setShowEditModal(true);
                        }}
                      />
                      &nbsp; &nbsp; &nbsp;
                      <Trash
                        onClick={() => {
                          setSelectedMerch(mrch);
                          setShowDeleteModal(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </Row>
    </Col>
  );
}
