import { findMaxDigits, prefersCelsius } from "./utilFunctions";

export const chartJsDefaultOptions = {
  plugins: {
    datalabels: {
      display: (context) => {
        // Hide 0s.
        return context.dataset.data[context.dataIndex] !== 0;
      },
      color: '#FFFFFF', // White
      font: { family: "Karla", size: 20 },
      formatter: Math.round,
      textStrokeColor: 'grey',
      textStrokeWidth: 4,
      // All commented out because when the bar height is small, the bubbles look awful.
      // color: '#000000', // Black
      // backgroundColor: '#FFFFFF', // White
      // borderRadius: 50, // Make bubble round
      rotation: 0,
    },
    zoom: {
      pan: {
        enabled: true,
        mode: "x",
      },
      zoom: {
        enabled: true,
        mode: "x",
        wheel: {
          enabled: true,
        },
      },
    },
  },
  scales: {},
};

export function oneDecimal(value, context) {
  return Math.round((value + Number.EPSILON) * 10) / 10;
}

export function defaultFormat(value, context) {
  return Math.round(value);
}

export function oneDecimalPercent(value, context) {
  return Math.round((value + Number.EPSILON) * 10) / 10 + "%";
}

export function oneDecimalMaybePercent(value, context) {
  if (isNaN(value)) {
    return value;
  }
  // Use different formatting based on dataset label or a custom property
  if (context.dataset.plotType === 'fraction') {
    return oneDecimalPercent(value);
  } else {
    return oneDecimal(value);
  }
}

// export function chartJsFormats(formatFunction) {
//   let opts = chartJsDefaultOptions;
//   opts["plugins"]["datalabels"]["formatter"] = formatFunction;
//   return opts;
// }
export function chartJsFormats(formatFunction) {

  // Create a new options object based on chartJsDefaultOptions
  let opts = JSON.parse(JSON.stringify(chartJsDefaultOptions));

  // Set the custom formatter
  opts.plugins.datalabels.formatter = formatFunction;

  return opts;

}

export const categoricalLabels = {
  // insert at "labels" under "datalabels"
  label: {
    formatter: function (value, ctx) {
      if (ctx.dataIndex < 0 || ctx.dataIndex >= ctx.dataset.count.length) {
        // Handle the error or return a default value
        return "Invalid data";
      }
      return Math.round((value + Number.EPSILON) * 10) / 10 + "%: " + ctx.dataset.count[ctx.dataIndex];
    },
  },
};
// This function will create the labels configuration object
// with access to the dropdownSelection from the component's props or state
export function createCategoricalLabels(dropdownSelection) {
  return {
    label: {
      formatter: function (value, ctx) {
        if (dropdownSelection === "percentage") {
          // Format as percentage
          return Math.round(value) + "%: " + ctx.dataset.count[ctx.dataIndex];
        } else if (dropdownSelection === "count") {
          // Format as count
          return ctx.dataset.count[ctx.dataIndex];
        } else if (dropdownSelection === "pie") {
          // Format as percentage for pi
          const totalCount = ctx.dataset.count.reduce(
            (sum, currentArray) =>
              sum +
              currentArray.reduce(
                (sumInner, currentNumber) => sumInner + currentNumber,
                0,
              ),
            0,
          );
          return Math.round((value / totalCount) * 1000) / 10 + "%";
        } else {
          // Default formatting
          return value;
        }
      },
    },
  };
}

// Note: fraction param and formatOptions const will be unnecessary one joined; wipe then.
export function generateChartOptions({ valueArray, yMax }) {
  let options = {...chartJsFormats(oneDecimalMaybePercent),};

  // Set y-axis limits if fraction is true
  if (yMax) {
    options.scales = { y: { min: 0, max: yMax} };
    // max: dropdownSelection === "count" ? undefined : 100,  // Only useful if dropdownSelection is implemented.
  }

  // Add rotation for labels if there are many bars or if the values have more than one digit
  if (valueArray && (valueArray.length > 7 && findMaxDigits(valueArray) > 1)) {
    options.plugins = {
      datalabels: {
        ...options.plugins.datalabels,
        rotation: -90
      }
    };
  }
  return options;
}

export function sortObjectByKeys(obj) {
  // Get the keys and sort them
  // sort the weekdays
  const sortedObj = {};
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const hasAnyWeekday = weekdays.some(day => day in obj);

  if (hasAnyWeekday) {
    const sortedObj = {};
    for (let weekday of weekdays) {
      if (weekday in obj) {
        sortedObj[weekday] = obj[weekday];
      }
    }
    return sortedObj;
  }

  // sort the rest
  const sortedKeys = Object.keys(obj).sort();
  for (let key of sortedKeys) {
    sortedObj[key] = obj[key];
  }

  return sortedObj;
}

export function fillMissingKeys(target, source) {
  if (typeof target !== 'object' || target === null) {
    return target;
  }

  for (let key in source) {
    if (source.hasOwnProperty(key)) {
      if (typeof source[key] === 'object' && source[key] !== null) {
        // If the property is an object, recursively call fillMissingKeys
        target[key] = target[key] || {};
        fillMissingKeys(target[key], source[key]);
      } else if (!(key in target)) {
        // If the key doesn't exist in the target, add it with a placeholder value
        target[key] = typeof source[key] === 'number' ? 0 : '';
      }
    }
  }
  return target;
}
export function modalCategoryOverlayOptions(overlayValues, modalValue, yMax, isWeather = false, isWeatherGrouped = false) {
  function modalCategoryOverlayFormatter(value, context) {
    const index = context.dataIndex;
    const overlayValue = Object.values(overlayValues)[index];
    if (isWeather) {
      if (context.dataset['label'] === 'Weather') {
        return "";
      }
      // return overlayValues['temp_c'];
    }

    // when it is the single bar
    if (typeof overlayValues === "string") {
      return overlayValues;
    }
    // when it is grouped
    return overlayValue !== undefined ? oneDecimalMaybePercent(overlayValue["value"], context) : '';
  }

  const options = chartJsFormats(modalCategoryOverlayFormatter);
  if (yMax) {
    options.scales = { y: { min: 0, max: yMax } };
  }
  options.plugins.legend = { display: false };
  options.plugins.tooltip = {
    callbacks: {
      label: function (context) {
        let overlayValue = Object.values(overlayValues)[context['dataIndex']];

        if (isWeather) {
          // Overlay = weather
          // weather bar tooltip
          if (context.dataset['label'] === 'Weather') {
            const suffix = prefersCelsius() ? "C" : "F";
            // grouped weather
            if (isWeatherGrouped) {
              return [context.dataset.label + ': ' + context.parsed.y + suffix + ' ',
              'Average Temperature' + ': ' + overlayValues.value[context.label]['temp_c'] + suffix,
              'Minimum Temperature' + ': ' + overlayValues.value[context.label]['min_temp_c'] + suffix,
              'Maximum Temperature' + ': ' + overlayValues.value[context.label]['max_temp_c'] + suffix,
              "Text" + ": " + overlayValues.value[context.label]['text']
              ];
            }
            // not grouped weather
            return [context.dataset.label + ': ' + context.parsed.y + suffix + ' ',
            'Average Temperature' + ': ' + overlayValues.value['temp_c'] + suffix,
            'Minimum Temperature' + ': ' + overlayValues.value['min_temp_c'] + suffix,
            'Maximum Temperature' + ': ' + overlayValues.value['max_temp_c'] + suffix,
            "Text" + ": " + overlayValues.value['text']
            ];
          }
          // any other tooltip
          return context.dataset.label + ': ' + context.parsed.y;
        }
        // when it is undefined
        if (overlayValue === undefined) {
          return context.dataset.label + ': ' + context.parsed.y;
        }
        // when it is the single bar
        if (typeof overlayValues === "string") {
          return [context.dataset.label + ': ' + context.parsed.y + ' ', overlayValues + ': ' + modalValue];
        }
        // grouped
        return [context.dataset.label + ': ' + context.parsed.y, overlayValue['value'] + ': ' + overlayValue['dcount']];
      },
    }
  }
  return options;
}


export const imagePlugin = {
  id: 'imagePlugin',
  afterDatasetsDraw: (chart, args, options) => {
    const { ctx, data, chartArea: { top, bottom, left, right, width, height } } = chart;

    data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);

      if (!meta.hidden) {
        meta.data.forEach((datapoint, index) => {
          const { x, y } = datapoint.tooltipPosition();

          // dataset.imageUrl is an array when it is grouped
          if (dataset.imageUrl) {
            const image = new Image();
            if (typeof dataset.imageUrl === 'object') {
              image.src = dataset.imageUrl[index];
            } else {
              image.src = dataset.imageUrl
            }

            const imageSize = 70; // Adjust this value to change the image size
            ctx.drawImage(image, x - imageSize / 2, y + imageSize - 10, imageSize, imageSize);
          }
        });
      }
    });
  }
};

export const twoLinearYScales = (options, chartData, overlay) => {
  return {
    y: {
      ...options.scales.y,
      type: "linear",
      display: true,
      position: "left",
      title: {
        display: true,
        text: chartData.name,
      },
      max: chartData.y_max,
    },
    yR: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false, // do not draw grid for the right axis
      },
      title: {
        display: true,
        text: overlay.name,
      },
      max: overlay.y_max,
    },
  };
}
