import { useState, useContext, useEffect } from "react";

import useFetch from "use-http";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import useFetchConfig from "../../Hooks/useFetchConfig";

import { MainContext } from "../../Providers/MainContext";

export default function LocationEditModal(props) {
  const fetchConfig = useFetchConfig();
  const { post, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const { customer } = useContext(MainContext);
  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState(null);
  const [city, setCity] = useState(null);
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setCountry(props.location.country);
    setRegion(props.location.region);
    setCity(props.location.city);
    setLocation(props.location.location);
  }, [props.location]);

  const handleClose = () => props.handleClose();

  const handleSubmit = async () => {
    await post(`/api/customer/${customer.id}/stores/${props.location.id}`, {
      country,
      region,
      city,
      location,
    });
    if (response.ok) {
      setError(null);
      handleClose();
    } else {
      setError("Error Updating Location");
    }
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton className="bg-light">
        <Modal.Title>
          Edit Location {props.location.id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        {error && <p className="text-danger">{error}</p>}
        <Form>
          <Form.Group className="mb-3" controlId="locationEditForm.country">
            <Form.Label>Country</Form.Label>
            <Form.Control
              className="bg-light"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="locationEditForm.region">
            <Form.Label>Region</Form.Label>
            <Form.Control
              className="bg-light"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="locationEditForm.city">
            <Form.Label>City</Form.Label>
            <Form.Control
              className="bg-light"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="locationEditForm.location">
            <Form.Label>Location</Form.Label>
            <Form.Control
              className="bg-light"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
