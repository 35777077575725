import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useFetch from "use-http";
import useFetchConfig from "../../Hooks/useFetchConfig";

import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Image from "react-bootstrap/Image";

import { Button, Container } from "react-bootstrap";
import { temperatureUnit, prefersCelsius } from "../../Utils/utilFunctions";
import useFilterStore from "../../Hooks/useFilterStore";
export default function MetricBox({ metricId, index, sidebar, getUrlParams, nameSuffix="" }) {
  const navigate = useNavigate();
  const fetchConfig = useFetchConfig();
  const { get, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const [metric, setMetric] = useState(null);
  const [error, setError] = useState(false);
  const { startDate, endDate, setStartDate, setEndDate, isFiltersRetained, resetAllFilters } = useFilterStore();
  const fetchMetricData = async () => {
    let data = await get(`/api/metric/${metricId}/box?` + getUrlParams());

    if (data === undefined) {
      return;
    }
    if (response.ok) {
      setError(false);
      setMetric(data.box);
    } else if (response) {
      setError("Error Loading metric: " + metricId);
    }
  };

  useEffect(() => {
    fetchMetricData();
    if (metricId && getUrlParams() !== "") {
      fetchMetricData();
    }
  }, [metricId, getUrlParams]);

  let colSize = 6;
  if (sidebar) {
    colSize = 12;
  }
  let cardClass;
  let cardHeaderClass;
  switch (index % 4) {
    case 0:
    case 3:
      cardClass =
        "border border-2 border-white rounded text-center bg-secondary text-white";
      cardHeaderClass = "text-white";
      break;
    case 1:
    case 2:
      cardClass = "rounded text-center bg-white text-secondary";
      cardHeaderClass = "";
      break;
  }

  const handleMetricClick = (metric) => {
    if (
      metric.handle !== "weather_locations" ||
      metric.overlayed_onto != null
    ) {
      // save the default date range when click
      // check if there is anything set on the date range
      if (isFiltersRetained === false) {
        resetAllFilters();
        navigate(`/metric/${metric.id}`);
        return;
      }
      if (parseDateRange(metric.description) && startDate !== "" && endDate !== "" && isFiltersRetained === true) {
        setStartDate(parseDateRange(metric.description).startDate);
        setEndDate(parseDateRange(metric.description).endDate);
      }
      navigate(`/metric/${metric.id}`);
    }
  }


  if (error) {
    return (
      <Col xs={12} md={colSize} xl={colSize} className="p-1 mx-auto">
        <Container
          className={`${cardClass} d-flex flex-column`}
          style={{ height: "160px" }}
        >
          <Row className="flex-grow-1 align-items-center justify-content-center">
            <Container
              className="fw-bold text-uppercase"
              style={{ fontSize: "16px" }}
            >
              Error Loading Metric: {error}
            </Container>
          </Row>
        </Container>
      </Col>
    );
  }
  if (!metric) {
    return (
      <Col xs={12} md={colSize} xl={colSize} className="p-1 mx-auto">
        <Container
          className={`${cardClass} d-flex flex-column`}
          style={{ height: "160px" }}
        >
          <Row className="flex-grow-1 align-items-center justify-content-center">
            <Container
              className="fw-bold text-uppercase"
              style={{ fontSize: "16px" }}
            >
              Loading...
            </Container>
          </Row>
        </Container>
      </Col>
    );
  }

  return (
    <Col xs={12} md={colSize} xl={colSize} className="p-1 mx-auto">
      <Container
        onClick={() => handleMetricClick(metric)}
        className={`${cardClass} d-flex flex-column`}
        style={{ height: "160px" }}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title={metric.description}
      >
        {/* METRIC HEADER: Image or value  */}
        <Row
          className="flex-grow-1 align-items-center justify-content-center"
          style={{ height: 66 }}
        >
          {_renderMetricHead(metric, cardHeaderClass)}
        </Row>
        {/* METRIC NAME */}
        <Row className="my-auto">
          <Container
            className="fw-bold text-uppercase"
            style={{ fontSize: "16px" }}
          >
            {metric.name}{nameSuffix}
          </Container>
        </Row>
        {/* METRIC INDICATOR */}
        <Row className="flex-grow-1 align-items-center" style={{ height: 66 }}>
          {_renderIndicator(metric)}
        </Row>
      </Container>
    </Col>
  );
}
const _renderIndicator = (metric) => {
  let postfix = metric.postfix;
  let tempCFontSize = 30;
  if (metric.type === "weather") {
    if (metric.value.c === false) {
      postfix = "";
    }
    if (metric.value.temp_c === "No events today") {
      tempCFontSize = 24;
    }
    return (
      <Container>
        {metric.prefix}
        <span style={{ fontSize: tempCFontSize }}>
          {temperatureUnit(metric.value.temp_c)}
        </span>
        {isNaN(metric.value.temp_c) ? "" : prefersCelsius() ? "C" : "F"}
      </Container>
    );
  }

  let formattedValue;
  let bgColour = "bg-primary";
  let variant = "primary";
  if (metric.indicator) {
    if (typeof metric.indicator.value !== "number") {
      formattedValue = metric.indicator.value;
    } else if (
      metric.indicator.value > 0 &&
      metric.indicator.type !== "Percent of Responses" &&
      metric.indicator.type !== "Vs Benchmark"
    ) {
      formattedValue = `+${metric.indicator.value}%`;
    } else {
      formattedValue = `${metric.indicator.value}%`;
    }
    if (metric.indicator.value < 0) {
      if (!metric.btg) {
        bgColour = "bg-danger";
        variant = "danger";
      }
    } else if (
      metric.indicator.value === 0 ||
      metric.indicator.value === "n/a"
    ) {
      bgColour = "bg-purple";
      variant = "purple";
    } else {  // value > 0
      if (metric.btg){
        bgColour = "bg-danger";
        variant = "danger";      
      }
    }
  }

  return (
    metric.indicator &&
    metric.indicator.type && (
      <>
        <Col xs={6} className="d-flex justify-content-center ">
          <div style={{ fontSize: "0.75rem" }} className="fw-bold">
            {metric.indicator.type}
          </div>
        </Col>
        <Col xs={6} md={6} xl={6} className="d-flex justify-content-center">
          <Button
            variant={variant}
            className={
              bgColour + " rounded-0 text-white fw-bold p-1 rounded-pill w-100"
            }
          >
            {formattedValue}
          </Button>
        </Col>
      </>
    )
  );
};
const _renderMetricHead = (metric, cardHeaderClass) => {
  // Categorical metrics are rendered in a different way
  if (metric.image) {
    return (
      <Image
        src={metric.image}
        alt={metric.name}
        style={{ objectFit: "contain", width: "auto", height: "50px" }}
      />
    );
  } else if (metric.type === "weather") {
    return (
      <Image
        src={metric.value.url}
        alt={metric.name}
        style={{ objectFit: "contain", width: "auto", height: "50px" }}
      />
    );
  } else if (
    metric.value !== "No data." &&
    (metric.type === "mode" || metric.type === "wordcloud")
  ) {
    let value = { dcount: 0 };
    metric.value.forEach((v) => {
      if (v.dcount > value.dcount) {
        value = v;
      }
    });
    return (
      <Container>
        <Row>
          {metric.prefix}
          {/* truncate texts */}
          <div className={`text-truncate display-${metric.size} fw-bold pb-2 ${cardHeaderClass}}`}>
            {value.value}
          </div>
          {metric.postfix}
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      {metric.prefix}
      <div className={`d-inline display-${metric.size} fw-bold`}>
        {metric.value}
      </div>
      {metric.postfix}
    </Container>
  );
};

// Parse the date range from the metric.description
// input:str = metric.description
// output: json = {startDate: date, endDate: date} or null
function parseDateRange(input) {
  const dateRangeMatch = input.match(/date_range:\s*(\d{4}-\d{2}-\d{2})\s*-\s*(\d{4}-\d{2}-\d{2})/);

  if (dateRangeMatch) {
    return {
      startDate: dateRangeMatch[1],
      endDate: dateRangeMatch[2]
    };
  }

  return null; // Return null if no date range is found
}