import React, { memo } from "react";
import WordcloudSingleChart from "./Wordcloud/WordcloudSingleChart";
import OverlayBarChart from "./Overlay/OverlayBarChart";
import OverlayGroupBarChart from "./Overlay/OverlayGroupBarChart";
import PieTable from "./Overlay/PieTable";
import { GaugeSingleChart } from "./Gauge/GaugeSingleChart";
import { NPSSingleChart } from "./Gauge/NPSSingleChart";
import OverlayCategoricalGroupChart from "./Overlay/OverlayCategoricalGroupChart";
import WordcloudGroupChart from "./Wordcloud/WordcloudGroupChart";
import ImpactTable from "./Table/ImpactTable";
import OverlayCategoricalSingleChart from "./Overlay/OverlayCategoricalSingleChart";
import CategoricalGroupBarChart from "./Categorical/CategoricalGroupBarChart";

const RenderChart = memo(
  ({ chartData, overlayData, categoricalDropdown }) => {
    const paramGroupBy = chartData.params.group_by;
    console.log(
      "renderChart's chartData",
      chartData,
      "paramGroupBy",
      paramGroupBy,
      "overlayData",
      overlayData,
      "categoricalDropdown",
      categoricalDropdown,
    );
    // groupBy irrelevant:
    switch (chartData.type) {
      case "shift_status":
        return (
          <CategoricalGroupBarChart
            chartData={chartData}
            dropdownSelection="count"
          />
        );
      case "pie_table":
        return (
          <PieTable
            data={chartData}
            overlay={overlayData}
            groupBy={paramGroupBy}
            dropdownSelection={categoricalDropdown}
          />
        );
      default:
        // groupBy relevant.
        if (!paramGroupBy) {
          switch (chartData.type) {
            case "fraction":
              return (
                <OverlayBarChart
                  chartData={chartData}
                  overlay={overlayData}
                  fraction={true}
                />
              );
            case "categorical":
            case "cat_axb":
              return (
                <OverlayCategoricalSingleChart
                  chartData={chartData}
                  overlay={overlayData}
                  dropdownSelection={categoricalDropdown}
                />
              );
            case "wordcloud":
              return <WordcloudSingleChart chartData={chartData} />;
            case "nps":
              return <NPSSingleChart chartData={chartData} />;
            case "gauge":
              return <GaugeSingleChart chartData={chartData} />;
            case "table":
              return <ImpactTable chartData={chartData} />;
            default:
              return (
                <OverlayBarChart chartData={chartData} overlay={overlayData} />
              );
          }
        } else {
          switch (chartData.type) {
            case "fraction":
              return (
                <OverlayGroupBarChart
                  chartData={chartData}
                  overlay={overlayData}
                  fraction={true}
                />
              );
            case "cat_axb":
            case "sumproduct":
            case "categorical":
              return (
                <OverlayCategoricalGroupChart
                  chartData={chartData}
                  overlay={overlayData}
                  dropdownSelection={categoricalDropdown}
                />
              );
            case "wordcloud":
              return <WordcloudGroupChart chartData={chartData} />;
            default:
              return (
                <OverlayGroupBarChart
                  chartData={chartData}
                  overlay={overlayData}
                />
              );
          }
        }
    }
  },
);

export default RenderChart;
