import { useState, useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";
import { CardHeader, Card, Form, Row, Col, Button } from "react-bootstrap";
import useFetch from "use-http";
import useFetchConfig from "../Hooks/useFetchConfig";
import { MainContext } from "../Providers/MainContext";
import SimpleTextInputCard from "../Components/MetricTemplateCreateComponents/SimpleTextInputCard";
import TrickyTextInputCard from "../Components/MetricTemplateCreateComponents/TrickyTextInputCard";
import SelectInputCard from  "../Components/MetricTemplateCreateComponents/SelectInputCard";
import SeparateInputsCard from  "../Components/MetricTemplateCreateComponents/SeparateInputsCard";
import handleStyle from  "../Components/MetricTemplateCreateComponents/handleStyle";
import ImageInputCard from  "../Components/MetricTemplateCreateComponents/ImageInputCard";
import SuggestedTextInputCard from  "../Components/MetricTemplateCreateComponents/SuggestedTextInputCard";

export default function MetricTemplateCreatePage() {
  const navigate = useNavigate();
  const fetchConfig = useFetchConfig();
  const { get, post, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const { customer } = useContext(MainContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [handle, setHandle] = useState("");
  const [existingHandles, setExistingHandles] = useState([]);
  const [handleError, setHandleError] = useState("Please enter a handle.");
  const [templateName, setTemplateName] = useState("");
  const [nameError, setNameError] = useState("Please enter a name.");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(
    "Please enter a description.",
  );
  const [l1Aggs, setL1Aggs] = useState([]);
  const [l1Agg, setL1Agg] = useState(null);
  const [l2Aggs, setL2Aggs] = useState([]);
  const [l2Agg, setL2Agg] = useState(null);
  const [questionLabels, setQuestionLabels] = useState("");
  const [questionLabelInputs, setQuestionLabelInputs] = useState([]);
  const [denominatorLabels, setDenominatorLabels] = useState(null);
  const [denominatorLabelInputs, setDenominatorLabelInputs] = useState([""]);
  const [image, setImage] = useState("");
  const [imageError, setImageError] = useState("");
  const [prefix, setPrefix] = useState("");
  const [postfix, setPostfix] = useState("");
  const [panel, setPanel] = useState("");
  const [existingPanels, setExistingPanels] = useState([]);
  const [defaultGrouping, setDefaultGrouping] = useState("");
  const [overrideGrouping, setOverrideGrouping] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortOrderInputs, setSortOrderInputs] = useState([]);
  const [dataType, setDataType] = useState("");
  const [sourceTable, setSourceTable] = useState("");
  const [sourceIdentifier, setSourceIdentifier] = useState("");
  const [chartLabel, setChartLabel] = useState("");
  const [notes, setNotes] = useState("");
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [size, setSize] = useState("");
  const [sizeError, setSizeError] = useState("");

  const existingSources = [
    { key: "typeform", value: "typeform" },
    { key: "crm", value: "crm" },
    { key: "voiceform", value: "voiceform" },
    { key: "sharepoint_inventory", value: "sharepoint_inventory" },
    { key: "yourls", value: "yourls" },
    { key: "besttime", value: "besttime" },
  ];
  const existingSourceIdentifiers = [
    "form_id",
    "job_code",
    "survey_id",
    "shorturl",
  ];

  useEffect(() => {
    fetchExisting();
    fetchL1Aggs();
    fetchL2Aggs();
  }, [customer]);

  useEffect(() => {
    if (questionLabelInputs) {
      let longLabelInputs = questionLabelInputs.filter(
        (input) => input && input.length > 6,
      );
      if (longLabelInputs.length > 0) {
        setQuestionLabels(`${handle},${longLabelInputs.join(",")}`);
      } else {
        setQuestionLabels(handle);
      }
    } else {
      setQuestionLabels(handle);
    }
  }, [handle, questionLabelInputs]);

  useEffect(() => {
    setImageError(
      "Please ensure your handle is set correctly, then upload an image (saved based on handle).",
    );
  }, [handle]);

  useEffect(() => {
    if (denominatorLabelInputs) {
      let longLabelInputs = denominatorLabelInputs.filter(
        (input) => input && input.length > 6,
      );
      if (longLabelInputs.length > 0) {
        setDenominatorLabels(longLabelInputs.join(","));
      } else {
        setDenominatorLabels(null);
      }
    } else {
      setDenominatorLabels(null);
    }
  }, [denominatorLabelInputs]);

  useEffect(() => {
    if (sortOrderInputs) {
      let longLabelInputs = sortOrderInputs.filter(
        (input) => input && input.length > 0,
      );
      if (longLabelInputs.length > 0) {
        setSortOrder(longLabelInputs.join(","));
      } else {
        setSortOrder(null);
      }
    } else {
      setDenominatorLabels(null);
    }
  }, [sortOrderInputs]);

  const handleHandleChange = (event) => {
    const { value } = event.target;
    const filteredValue = handleStyle(value);
    if (filteredValue.length < 6) {
      setHandleError(
        "If your handle is too short, it'll be hard for future handles to be distinct. Make it longer.",
      );
    } else if (filteredValue.length > 63) {
      setHandleError(
        "If your handle is too long, it won't fit in the database field.",
      );
    } else if (filteredValue.endsWith("_")) {
      setHandleError("End your handle with a letter.");
    } else if (existingHandles.includes(filteredValue)) {
      setHandleError("Handle already exists.");
    } else if (
      existingHandles.some((handle) => handle.startsWith(filteredValue))
    ) {
      setHandleError(
        "An existing handle starts with the same characters. Make the new handle distinct.",
      );
    } else if (
      existingHandles.some((handle) => filteredValue.startsWith(handle))
    ) {
      setHandleError(
        "The new handle is the same as the start of an existing handle. Make the new handle distinct.",
      );
    } else {
      setHandleError("");
    }
    setHandle(filteredValue);
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    if (value.length < 1) {
      setNameError(
        "If your name is too short, it'll be hard to identify your metric. Make it longer.",
      );
    } else if (value.length > 24) {
      setNameError(
        "If your name is too long, it'll overflow the MetricBox. Make it shorter.",
      );
    } else {
      setNameError("");
    }
    setTemplateName(value);
  };

  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    if (value.length < 1) {
      setDescriptionError(
        "If your description is too short, it'll be hard for users to understand your metric. Make it longer.",
      );
    } else if (value.length > 254) {
      setDescriptionError(
        "If your description is too long, nobody will read it. Make it shorter.",
      );
    } else {
      setDescriptionError("");
    }
    setDescription(value);
  };

  const handleSizeChange = (event) => {
    const { value } = event.target;
    const validValues = ["","6","7","8","9"];
    if (!validValues.includes(value)) {
      setSizeError(
        "Pick a valid value.",
      );
    } else {
      setSizeError("");
    }
    setSize(value);
  };

  const fetchExisting = async () => {
    if (!customer.id) return;
    let data = await get(`/api/metrictemplates`);
    if (response.ok) {
      setError(null);
      setExistingHandles([...new Set(data.map((_mt) => _mt.handle))]);
      setExistingPanels([...new Set(data.filter(_mt => _mt.panel !== null).map(_mt => _mt.panel))]);
    } else {
      setError("Error loading exiting MetricTemplates.");
    }
  };

  const pleaseSetCardHeader = (cardHeader) => {
    return `Please set ${cardHeader}.`;
  };

  const fetchL1Aggs = async () => {
    if (!customer.id) return;
    let data = await get(`/api/l1_aggs`);
    if (response.ok) {
      setError(null);
      setL1Aggs(data);
    } else {
      setError("Error loading L1 aggregations.");
    }
  };

  const fetchL2Aggs = async () => {
    if (!customer.id) return;
    let data = await get(`/api/l2_aggs`);
    if (response.ok) {
      setError(null);
      setL2Aggs(data);
    } else {
      setError("Error loading L2 aggregations.");
    }
  };

  const createImageFilenameTemplate = (handle) => {
    return function (fileExtension) {
      return `${handle}_icon.${fileExtension}`;
    };
  };

  // TODO: add checks to ensure the default_grouping and override_grouping groupings exist first.

  const handleCreateMetricTemplate = async () => {
    setIsLoading(true);
    setDisabled(true);
    let data = await post(`/api/metrictemplates`, {
      name: templateName,
      handle: handle,
      description: description,
      l1_aggregation: l1Agg,
      l2_aggregation: l2Agg,
      prefix: prefix,
      postfix: postfix,
      panel: panel,
      chart_label: chartLabel,
      question_labels: questionLabels,
      denominator_labels: denominatorLabels,
      image: image,
      default_grouping: defaultGrouping,
      override_grouping: overrideGrouping,
      sort_order: sortOrder,
      size: size,
      // optionals
      data_type: dataType,
      source_table: sourceTable,
      source_identifier: sourceIdentifier,
      notes: notes,
    });
    setIsLoading(false);
    if (response.ok) {
      setError(null);
      setSuccess(true);
    } else if (response.status === 409) {
      setDisabled(false);
      setError(
        "Created locally, but not in database; delete MetrcTemplate with sadmin & try again.",
      );
    } else {
      setDisabled(false);
      setError(
        "Unexpected error creating metric template. Are any of your other error messages showing?",
      );
    }
  };

  const reload = () => {
    // scroll to the top of the page
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const whichButton = () => {
    if (isLoading) {
      return <Button className="greyed-out w-100">Creating...</Button>;
    } else if (success) {
      return (
        <Row>
          <Col>
            <Button onClick={reload} className="w-100">
              Reload
            </Button>
          </Col>
          <Col>
            <Button onClick={() => navigate("/home")} className="w-100">
              Home
            </Button>
          </Col>
        </Row>
      );
    } else {
      return (
        <Button onClick={handleCreateMetricTemplate} className="w-100">
          Create MetricTemplate
        </Button>
      );
    }
  };

  return (
    <Col
      xs={8}
      md={6}
      lg={6}
      xl={6}
      className={isLoading ? "loading-cursor mx-auto" : "mx-auto"}
    >
      <br />
      <Row className="mx-auto">
        <h1 className="text-light">Create MetricTemplate</h1>
      </Row>
      <br />
      <Row className="mx-auto">
        <Col className="mx-auto">
          <Row className="mb-3">
            <Card>
              <CardHeader>Required parameters:</CardHeader>
              <Card.Body>
                <Row className="row-cols-1 row-cols-xl-3 g-4">
                  <TrickyTextInputCard
                    externalState={handle}
                    handleFormChange={handleHandleChange}
                    cardError={handleError}
                    setCardError={setHandleError}
                    cardHeader="Handle"
                    controlId="metricTemplateCreate.handle"
                    label="the true name of the metric"
                    placeholder="eg. my_new_metric"
                    maxLength={64}
                    disabled={disabled}
                  />
                  <TrickyTextInputCard
                    externalState={templateName}
                    handleFormChange={handleNameChange}
                    cardError={nameError}
                    setCardError={setNameError}
                    cardHeader="Name"
                    controlId="metricTemplateCreate.name"
                    label="default 'name' of the metric shown to users"
                    placeholder="My New Metric"
                    maxLength={25}
                    disabled={disabled}
                  />
                  <TrickyTextInputCard
                    externalState={description}
                    handleFormChange={handleDescriptionChange}
                    cardError={descriptionError}
                    setCardError={setDescriptionError}
                    cardHeader="Description"
                    controlId="metricTemplateCreate.description"
                    label="description of metric, shown in MetricBox tooltip"
                    placeholder="My New Metric: from source X, calculated by method Y"
                    maxLength={255}
                    disabled={disabled}
                  />
                  <SelectInputCard
                    options={l1Aggs}
                    externalState={l1Agg}
                    setExternalState={setL1Agg}
                    cardHeader="L1 Aggregation"
                    controlId="metricTemplateCreate.l1agg"
                    label="how the metric's Answers gets processed to return L1 values"
                    initialErrorGenerator={pleaseSetCardHeader}
                    disabled={disabled}
                  />
                  <SelectInputCard
                    options={l2Aggs}
                    externalState={l2Agg}
                    setExternalState={setL2Agg}
                    cardHeader="L2 Aggregation"
                    controlId="metricTemplateCreate.l2agg"
                    label="how the metric's Answers gets processed to return L2 values"
                    initialErrorGenerator={pleaseSetCardHeader}
                    disabled={disabled}
                  />
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <Row className="mb-3">
            <Card>
              <CardHeader>Conditional Parameters:</CardHeader>
              <Card.Body>
                <Row className="row-cols-1 row-cols-xl-3 g-4">
                  {!handleError && (
                    <SeparateInputsCard
                      labelName="question label"
                      finalState={questionLabels}
                      externalState={questionLabelInputs}
                      setExternalState={setQuestionLabelInputs}
                      cardHeader="Question Labels"
                      controlId="metricTemplateCreate.questionLabels"
                      label="the labels applied to questions this metric uses"
                      disabled={disabled}
                    />
                  )}
                  {(l2Agg === "fraction" || l1Agg === "fraction") && (
                    <SeparateInputsCard
                      labelName="denominator label"
                      finalState={denominatorLabels}
                      externalState={denominatorLabelInputs}
                      setExternalState={setDenominatorLabelInputs}
                      cardHeader="Denominator Labels"
                      controlId="metricTemplateCreate.denominatorLabels"
                      label={
                        "the labels applied to questions that serve as the" +
                        "denominator for fraction-style metrics"
                      }
                      disabled={disabled}
                    />
                  )}
                  {l1Agg === "image" && !handleError && (
                    <ImageInputCard
                      fileNameGenerator={createImageFilenameTemplate(handle)}
                      image={image}
                      setImage={setImage}
                      imageError={imageError}
                      setImageError={setImageError}
                      cardHeader="Image"
                      disabled={disabled}
                    />
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <Row className="mb-3">
            <Card>
              <CardHeader>Optional Parameters:</CardHeader>
              <Card.Body>
                <Row className="row-cols-1 row-cols-xl-3 g-4">
                  <SimpleTextInputCard
                    externalState={prefix}
                    setExternalState={setPrefix}
                    cardHeader="Prefix"
                    controlId="metricTemplateCreate.prefix"
                    label={
                      "In case there is a prefix to be shown before the value in the MetricBox"
                    }
                    placeholder="eg. $"
                    maxLength={12}
                    disabled={disabled}
                  />
                  <SimpleTextInputCard
                    externalState={postfix}
                    setExternalState={setPostfix}
                    cardHeader="Postfix"
                    controlId="metricTemplateCreate.postfix"
                    label="The unit shown after the value in the Metricbox"
                    placeholder="eg. %"
                    maxLength={12}
                    disabled={disabled}
                  />
                  <SuggestedTextInputCard
                    labelname="panel name"
                    externalState={panel}
                    setExternalState={setPanel}
                    suggestionOptions={existingPanels}
                    cardHeader="Panel"
                    controlId="metricTemplateCreate.panel"
                    label={
                      "provides a default panel to the metrics derived from" +
                      "the the metrictemplate"
                    }
                    placeholder="eg. Event Success"
                    maxLength={255}
                    disabled={disabled}
                  />
                  <SimpleTextInputCard
                    externalState={defaultGrouping}
                    setExternalState={setDefaultGrouping}
                    cardHeader="Default Grouping"
                    controlId="metricTemplateCreate.defaultGrouping"
                    label="if the L2 chart should show up grouped by default, specify here"
                    placeholder="eg. day_of_week"
                    maxLength={64}
                    disabled={disabled}
                  />
                  <SimpleTextInputCard
                    externalState={overrideGrouping}
                    setExternalState={setOverrideGrouping}
                    cardHeader="Override Grouping"
                    controlId="metricTemplateCreate.overrideGrouping"
                    label="if the L2 chart should only show up grouped by a particular grouping, specify here"
                    placeholder="eg. date"
                    maxLength={64}
                    disabled={disabled}
                  />
                  <SeparateInputsCard
                    labelName="entries"
                    finalState={sortOrder}
                    externalState={sortOrderInputs}
                    setExternalState={setSortOrderInputs}
                    cardHeader="Sort Order"
                    controlId="metricTemplateCreate.sortOrder"
                    label={
                      "if the L2 chart should be sorted some special way," +
                      "specify here (in order) eg. positive,neutral,negative"
                    }
                    checkShort={false}
                    disabled={disabled}
                  />
                  <TrickyTextInputCard
                    externalState={size}
                    handleFormChange={handleSizeChange}
                    cardError={sizeError}
                    setCardError={setSizeError}
                    cardHeader="Header Size"
                    controlId="metricTemplateCreate.size"
                    label="size of header; integer from 9-6, 9 small, 6 large"
                    placeholder="eg. 6"
                    maxLength={255}
                    disabled={disabled}
                  />
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <Row className="mb-3">
            <Card>
              <CardHeader>Record-keeping Parameters:</CardHeader>
              <Card.Body>
                <Row className="row-cols-1 row-cols-xl-3 g-4">
                  <SelectInputCard
                    options={[
                      { key: "numeric", value: "numeric" },
                      { key: "string", value: "string" },
                    ]}
                    externalState={dataType}
                    setExternalState={setDataType}
                    cardHeader="Data Type"
                    controlId="metricTemplateCreate.dataType"
                    label={
                      "won't be used until we implement generic overlays &" +
                      "groupings, but for sanity's sake might as well add it"
                    }
                    disabled={disabled}
                  />
                  <SelectInputCard
                    options={existingSources}
                    externalState={sourceTable}
                    setExternalState={setSourceTable}
                    cardHeader="Source Table"
                    controlId="metricTemplateCreate.sourceTable"
                    label="where data for metric comes from"
                    disabled={disabled}
                  />
                  <SuggestedTextInputCard
                    labelname="source identifier"
                    externalState={sourceIdentifier}
                    setExternalState={setSourceIdentifier}
                    suggestionOptions={existingSourceIdentifiers}
                    cardHeader="Source Identifier"
                    controlId="metricTemplateCreate.sourceIdentifier"
                    label="how we identify which data belongs in a given dashboard"
                    placeholder="eg. form_id"
                    maxLength={12}
                    disabled={disabled}
                  />
                  <SimpleTextInputCard
                    externalState={chartLabel}
                    setExternalState={setChartLabel}
                    cardHeader={"Chart Label"}
                    controlId={"metricTemplateCreate.chartLabel"}
                    label={
                      "the idea was, to allow us to label the default bars as something other than value when requested, " +
                      "however, they aren't labelled at all by default; this is ingested & added to MetricTemplate objects, " +
                      "but isn't actually used *by* metrics (yet?)"
                    }
                    placeholder={"eg. minutes"}
                    maxLength={12}
                    disabled={disabled}
                  />
                  <SimpleTextInputCard
                    externalState={notes}
                    setExternalState={setNotes}
                    cardHeader={"Notes"}
                    controlId={"metricTemplateCreate.notes"}
                    label={"in case there's anything else we should know about"}
                    placeholder={"eg. This only matters on Tuesdays."}
                    maxLength={255}
                    disabled={disabled}
                  />
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <br />
        </Col>
      </Row>
      {error && (
        <Row>
          <p className="text-danger">{error}</p>
        </Row>
      )}
      <Row className="mx-auto">
        <Col key="createButton" className="w-100">
          {whichButton()}
        </Col>
      </Row>
      <br />
    </Col>
  );
}
