import React from "react";
import Router from "./Router";
import { Chart as ChartJS, registerables, CategoryScale, LinearScale, Title, Tooltip } from "chart.js";
import { MainContextProvider } from "./Providers/MainContext";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';

ChartJS.register(ChartDataLabels);
ChartJS.register(...registerables);
ChartJS.register(zoomPlugin);
ChartJS.register(annotationPlugin);
ChartJS.register(CategoryScale, LinearScale, MatrixController, MatrixElement, Title, Tooltip);

// Define and register a global plugin to set the background color
// add a karla font 
ChartJS.defaults.font.family = "Karla";
ChartJS.register({
  id: "globalBackground",
  beforeDraw: (chart) => {
    const ctx = chart.ctx;
    ctx.save();
    ctx.fillStyle = "#fff"; // background color for chart area
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
});

function App() {
  return (
    <MainContextProvider>
      <Router />
    </MainContextProvider>
  );
}

export default App;
