import { create } from 'zustand';

const useFilterStore = create((set) => ({
    disabled: true,
    overlayOptions: [],
    overlaySelection: '',
    groupBy: '',
    startdate: '',
    endDate: '',
    locations: new Set(),
    cities: new Set(),
    regions: new Set(),
    countries: new Set(),
    selectedLocations: new Set(),
    selectedCities: new Set(),
    selectedRegions: new Set(),
    selectedCountries: new Set(),
    brands: new Set(),
    categories: new Set(),
    products: new Set(),
    selectedBrands: new Set(),
    selectedCategories: new Set(),
    selectedProducts: new Set(),
    filterGroups: {},
    selectedFilterGroups: {},
    filterGroupSwitchStates: {},
    getUrlParams: () => "",
    isFiltersRetained: true,

    setDisabled: (value) => set({ disabled: value }),
    setOverlayOptions: (options) => set({ overlayOptions: options }),
    setOverlaySelection: (selection) => set({ overlaySelection: selection }),
    setGroupBy: (value) => set({ groupBy: value }),
    setStartDate: (date) => set({ startdate: date }),
    setEndDate: (date) => set({ endDate: date }),
    setLocations: (locations) => set({ locations: locations }),
    setCities: (cities) => set({ cities: cities }),
    setRegions: (regions) => set({ regions: regions }),
    setCountries: (countries) => set({ countries: countries }),
    setSelectedLocations: (locations) => set({ selectedLocations: locations }),
    setSelectedCities: (cities) => set({ selectedCities: cities }),
    setSelectedRegions: (regions) => set({ selectedRegions: regions }),
    setSelectedCountries: (countries) => set({ selectedCountries: countries }),
    setBrands: (brands) => set({ brands: brands }),
    setCategories: (categories) => set({ categories: categories }),
    setProducts: (products) => set({ products: products }),
    setSelectedBrands: (brands) => set({ selectedBrands: brands }),
    setSelectedCategories: (categories) => set({ selectedCategories: categories }),
    setSelectedProducts: (products) => set({ selectedProducts: products }),
    setFilterGroups: (groups) => set({ filterGroups: groups }),
    setSelectedFilterGroups: (groups) => set({ selectedFilterGroups: groups }),
    setFilterGroupSwitchStates: (states) => set({ filterGroupSwitchStates: states }),
    setGetUrlParams: (getUrlParams) => set({ getUrlParams: getUrlParams }),
    handleToggleFilterModal: () => set((state) => ({ filterOpen: !state.filterOpen })),
    handleIsFiltersRetained: () => set((state) => ({ isFiltersRetained: !state.isFiltersRetained })),
    // please add any global states below for reset if you have added them above
    resetAllFilters: (retainFilters) =>
        set({
            disabled: true,
            overlayOptions: [],
            overlaySelection: '',
            groupBy: '',
            startdate: '',
            endDate: '',
            locations: new Set(),
            cities: new Set(),
            regions: new Set(),
            countries: new Set(),
            selectedLocations: new Set(),
            selectedCities: new Set(),
            selectedRegions: new Set(),
            selectedCountries: new Set(),
            brands: new Set(),
            categories: new Set(),
            products: new Set(),
            selectedBrands: new Set(),
            selectedCategories: new Set(),
            selectedProducts: new Set(),
            filterGroups: {},
            selectedFilterGroups: {},
            filterGroupSwitchStates: {},
            getUrlParams: () => "",
            isFiltersRetained: retainFilters ? true : false,
        }),

}));

export default useFilterStore;